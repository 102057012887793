import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import { supabase } from "./Red";

const cookies = new Cookies();

function Сhampionships() {
  const [gameResults, setGameResults] = useState([]);
  const [games, setGames] = useState([]);
  const [applications, setApplications] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [athletes, setAthletes] = useState([]);
  const [athletesAdd, setAthletesAdd] = useState(0);
  const [isApplicationsVisible, setIsApplicationsVisible] = useState(false);
  const [useAthletes, setUseAthletes] = useState(false);
  const navigate = useNavigate();
  const isLogged = cookies.get("logged") === true;

  const { sport } = useParams();
  const [searchParams] = useSearchParams();
  const gameId = searchParams.get("gameid");

  const [coach, setCoach] = useState({
    name: "",
    age: "",
    experience: "",
  });

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const { data: dataGames } = await supabase
        .from("Games")
        .select()
        .eq("id", gameId);
      setGames(dataGames);

      setAthletesAdd(
        Array.from({ length: dataGames[0]?.team_number || 0 }, () => ({
          name: "",
          age: "",
        }))
      );

      if (!dataGames.some((game) => game.id == gameId)) navigate(`/${sport}`);

      if (isLogged == true) {
        const { data: dataApplications } = await supabase
          .from("Applications_of_athletes")
          .select()
          .eq("game_id", gameId);
        setApplications(dataApplications);
      }

      const { data: dataGame_Result } = await supabase
        .from("Game_Results")
        .select()
        .eq("game_id", gameId);
      setGameResults(dataGame_Result);

      const { data: dataParticipiants } = await supabase
        .from("Participants_of_the_game")
        .select()
        .eq("game_id", gameId);
      setParticipants(dataParticipiants);

      const { data: dataAthletes } = await supabase.from("Athletes").select();
      setAthletes(dataAthletes);

      setLoading(false);
    };

    fetch();
  }, []);

  const teams = participants.reduce((acc, participant) => {
    const athlete = athletes.find(
      (athlete) => athlete.id === participant.athlet_id
    );
    const team = acc.find((team) => team.team_id === participant.team_id);

    if (team) {
      team.athletes.push(athlete && athlete.name);
    } else {
      acc.push({
        team_id: participant.team_id,
        athletes: [athlete && athlete.name],
      });
    }

    return acc;
  }, []);
  
  while (teams.length < games[0]?.team_count) {
    teams.push({
      team_id: `${teams.length + 1}`,
      athletes: [""],
    });
  }

  useEffect(() => {
    const checkFullTeam = async () => {
      let isFullTeam = teams.every((team) =>
        team.athletes?.every((athlet) => athlet !== "")
      );

      if (teams.length === 0) isFullTeam = false;

      if (isFullTeam) {
        const { error: errorApplication } = await supabase
          .from("Applications_of_athletes")
          .delete()
          .eq("game_id", gameId);

        if (errorApplication) {
          console.error("Ошибка при удалении заявок:", errorApplication);
        }
      }
    };

    checkFullTeam();
  }, [teams]);

  const totalRounds = Math.log2(games[0]?.team_count);
  const rounds = [];
  const winnerGame = [];
  let currentRound = teams.map((team) => {
    const athletes = team.athletes || [];
    return {
      id: team.team_id,
      name: athletes.length > 0 ? athletes.join(",\n") : "Данные отсутствуют",
    };
  });

  for (let round = 0; round < totalRounds; round++) {
    const nextRound = [];
    for (let i = 0; i < currentRound.length; i += 2) {
      const team1 = currentRound[i];
      const team2 = currentRound[i + 1];

      const winnerEntry = gameResults.find((result) => {
        return (
          (result.team_id === team1?.id || result.team_id === team2?.id) &&
          result.stage === round + 1
        );
      });

      let winner = null;

      if (winnerEntry) {
        const winningTeam = teams.find(
          (t) => t.team_id === winnerEntry.team_id
        );

        if (winningTeam) {
          winner = {
            id: winningTeam.team_id,
            name:
              winningTeam.athletes?.length > 0
                ? winningTeam.athletes.join(",\n")
                : "Данные отсутствуют",
          };
        }
      }
      nextRound.push(winner ? winner : { id: null, name: "" });
    }

    rounds.push(currentRound);
    currentRound = nextRound;
    if (round + 1 == totalRounds) {
      winnerGame.push(nextRound);
    }
  }

  const handleOverlayOpen = () => setOverlayVisible(true);

  const handleOverlayClose = () => setOverlayVisible(false);

  const handleApplicationsOpen = () => setIsApplicationsVisible(true);

  const handleApplicationsClose = () => setIsApplicationsVisible(false);

  const handleAthleteChange = (index, field, value) => {
    setAthletesAdd((prev) => {
      const newAthletes = [...prev];
      if (useAthletes && field === "name") {
        const selectedAthlete = athletes.find((athl) => athl.name == value);  
        if (!selectedAthlete) return prev;
        const isSended = applications.some(
          (app) => app.name == selectedAthlete.name
        );
        const isAdded = teams.some((team) =>
          team.athletes?.some((athlet) => athlet == selectedAthlete.name)
        );
        console.log(isSended);
        if (newAthletes.length > 0) {
          if (isAdded) {
            swal("Ошибка!", "Выбранный спортсмен уже участвует в игре", "error");
            return prev;
          }          
          if (isSended) {
            swal(
              "Ошибка!",
              "Выбранный спортсмен уже находится на рассмотрении",
              "error"
            );
            return prev;
          }
          const isAlreadySelected = newAthletes.some(
            (athl, i) => i !== index && athl.name === value
          );
          if (isAlreadySelected) {
            swal("Ошибка!", "Этот спортсмен уже выбран", "error");
            return prev;
          }
          for (let i = index - 1; i >= 0; i--) {
            if (
              newAthletes[i].coach_id &&
              newAthletes[i].coach_id !== selectedAthlete.coach_id
            ) {
              swal(
                "Ошибка!",
                "Выбранные спортсмены должны быть связаны с одним тренером",
                "error"
              );
              return prev;
            }
          }
        }
  
        newAthletes[index] = {
          ...newAthletes[index],
          id: selectedAthlete.id,
          name: value,
          age: selectedAthlete.age,
          coach_id: selectedAthlete.coach_id
        };
      } else {
        newAthletes[index] = { ...newAthletes[index], [field]: value };
      }
  
      return newAthletes;
    });
  };
  
  const handleCoachChange = (field, value) => {
    setCoach((prevCoach) => ({ ...prevCoach, [field]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (useAthletes && athletesAdd) {
        const checkAthlet = athletesAdd.some(
          (ath) => ath.name == "" || ath.age == ""
        );
        if (checkAthlet) {
          swal("Ошибка!", "Выберите всех спортсменов", "error");
          return;
        }
        for (const athlete of athletesAdd) {
          const { data: dataCoach } = await supabase
            .from("Coaches")
            .select()
            .eq("id", athlete.coach_id);

          const { error: errorAthlete } = await supabase
            .from("Applications_of_athletes")
            .insert([
              {
                name: athlete.name,
                age: athlete.age,
                name_coach: dataCoach[0].name,
                age_coach: dataCoach[0].age,
                experience_coach: dataCoach[0].experience,
                game_id: gameId,
              },
            ]);
          if (errorAthlete) {
            console.log(errorAthlete);
            swal("Ошибка!", "Не удалось отправить заявку", "error");
            return;
          }
        }
      } else {
        let emptyFields = athletesAdd.some(
          (athlete) => !athlete.name || !athlete.age
        );
        if (emptyFields) {
          swal(
            "Ошибка!",
            "Все поля для спортсменов должны быть заполнены!",
            "error"
          );
          return;
        }
        emptyFields = !coach.age || !coach.experience || !coach.name;
        if (emptyFields) {
          swal(
            "Ошибка!",
            "Все поля для тренера должны быть заполнены!",
            "error"
          );
          return;
        }
        const underageAthletes = athletesAdd.some(
          (athlete) => Number(athlete.age) < 18
        );
        if (underageAthletes) {
          swal(
            "Ошибка!",
            "Возраст всех спортсменов должен быть не менее 18 лет!",
            "error"
          );
          return;
        }
        if (
          Number(coach.age) < 18 ||
          Number(coach.age) - Number(coach.experience) < 18
        ) {
          swal(
            "Ошибка!",
            "Тренер должен быть старше 18 лет и опыт работы не может быть больше его возраста",
            "error"
          );
          return;
        }
        for (const athlete of athletesAdd) {
          const { error: errorAthlete } = await supabase
            .from("Applications_of_athletes")
            .insert([
              {
                name: athlete.name,
                age: athlete.age,
                name_coach: coach.name,
                age_coach: coach.age,
                experience_coach: coach.experience,
                game_id: gameId,
              },
            ]);
          if (errorAthlete) {
            console.log(errorAthlete);
            swal("Ошибка!", "Не удалось отправить заявку", "error");
            return;
          }
        }
      }
      window.location.reload();
      handleOverlayClose();
    } catch (error) {
      console.error("Ошибка при отправке данных:", error.message);
      alert("Ошибка при отправке заявки.");
    }
  };

  const groupedByCoach = applications.reduce((acc, application) => {
    console.log(applications)
    const coachName = application.name_coach;
    let existingCoachGroup = acc.find(
      (group) => group.coach === coachName && group.players.length < games[0].team_number
    );
  
    if (existingCoachGroup) {
      existingCoachGroup.players.push(application);
    } else {
      acc.push({
        id: application.id,
        coach: coachName,
        coach_age: application.age_coach,
        coach_experience: application.experience_coach,
        players: [application],
      });
    }
  
    return acc;
  }, []);

  const Approve = async (coachmass) => {
    try {
      const existingAthletes = coachmass.players
        .map((player) => {
          const matchedAthlete = athletes.find(
            (athlete) =>
              athlete.name === player.name && athlete.age === player.age
          );
          return matchedAthlete ? { ...player, id: matchedAthlete.id } : null;
        })
        .filter(Boolean);

      debugger;

      let newCoachId;
      
      if (existingAthletes.length == 0) {
        const { data: lastCoach, error: lastCoachError } = await supabase
          .from("Coaches")
          .select("id")
          .order("id", { ascending: false })
          .limit(1);

        if (lastCoachError) {
          console.error(lastCoachError);
          swal("Ошибка!", "Не удалось получить ID тренера", "error");
          return;
        }

        newCoachId = lastCoach?.[0]?.id ? lastCoach[0].id + 1 : 1;

        const { error: coachError } = await supabase.from("Coaches").insert([
          {
            id: newCoachId,
            name: coachmass.coach,
            age: coachmass.coach_age,
            experience: coachmass.coach_experience,
          },
        ]);

        if (coachError) {
          console.error(coachError);
          swal("Ошибка!", "Не удалось добавить тренера", "error");
          return;
        }
      }

      const { data: lastAthlete, error: lastAthleteError } = await supabase
          .from("Athletes")
          .select("id")
          .order("id", { ascending: false })
          .limit(1);

        if (lastAthleteError) {
          console.error(lastAthleteError);
          swal("Ошибка!", "Не удалось получить id спортсмена", "error");
          return;
        }

      const { data: dataParticipants_of_the_game } = await supabase
        .from("Participants_of_the_game")
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      const { data: lastTeamId } = await supabase
        .from("Participants_of_the_game")
        .select("team_id")
        .eq("game_id", gameId)
        .order("team_id", { ascending: false })
        .limit(1);

      let teamIdNew = lastTeamId?.[0]?.team_id ? lastTeamId[0].team_id + 1 : 1;

      let newPartId = dataParticipants_of_the_game?.[0]?.id
        ? dataParticipants_of_the_game[0].id + 1
        : 1;

      let newAthleteId = lastAthlete?.[0]?.id ? lastAthlete[0].id + 1 : 1;

      let xId = 0;

      for (const athlete of coachmass.players) {
        if (existingAthletes.length == 0) {
          const { data: newAthlet, error: errorAthlete } = await supabase
            .from("Athletes")
            .insert([
              {
                id: newAthleteId,
                name: athlete.name,
                age: athlete.age,
                coach_id: newCoachId,
              },
            ])
            .select();

          if (errorAthlete) {
            console.error(errorAthlete);
            swal(
              "Ошибка!",
              `Не удалось добавить спортсмена ${athlete.name}`,
              "error"
            );
            return;
          }
          const { error: errorParticipants_of_the_game } = await supabase
            .from("Participants_of_the_game")
            .insert([
              {
                id: newPartId,
                game_id: gameId,
                athlet_id: newAthleteId,
                team_id: teamIdNew,
              },
            ]);
          if (errorParticipants_of_the_game) {
            console.error(errorParticipants_of_the_game);
            swal(
              "Ошибка!",
              `Не удалось добавить учатника игры ${athlete.name}`,
              "error"
            );
            return;
          }
        } else {
          const { error: errorParticipants_of_the_game } = await supabase
            .from("Participants_of_the_game")
            .insert([
              {
                id: newPartId,
                game_id: gameId,
                athlet_id: existingAthletes[xId].id,
                team_id: teamIdNew,
              },
            ]);
          if (errorParticipants_of_the_game) {
            console.error(errorParticipants_of_the_game);
            swal(
              "Ошибка!",
              `Не удалось добавить учатника игры ${athlete.name}`,
              "error"
            );
            return;
          }
        }
        const { error: errorApplication } = await supabase
          .from("Applications_of_athletes")
          .delete()
          .eq("id", athlete.id);

        if (errorApplication) {
          swal("Ошибка!", "Не удалось удалить заявку", "error");
          return;
        }
        xId++;
        newPartId++;
        newAthleteId++;
      }

      window.location.reload();
    } catch (err) {
      console.error(err);
      swal("Ошибка!", "Произошла ошибка при обработке заявки", "error");
    }
  };

  const Reject = async (coachmass) => {
    const { error: errorApplication } = await supabase
      .from("Applications_of_athletes")
      .delete()
      .eq("name_coach", coachmass.coach)
      .eq("age_coach", coachmass.coach_age);
    if (errorApplication) {
      swal("Ошибка!", "Не удалось удалить заявку", "error");
      return;
    }
    window.location.reload();
  };

  const handleWinner = async (athletId, athletName, stage) => {
    console.log(teams);
    if (!athletId || !athletName || stage == "final" || !isLogged) return;

    swal({
      title: "Установить победителя?",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const { data: dataLastGR } = await supabase
            .from("Game_Results")
            .select("id")
            .order("id", { ascending: false })
            .limit(1);

          const teamIdNew = dataLastGR?.[0]?.id ? dataLastGR[0].id + 1 : 1;

          const { error } = await supabase.from("Game_Results").insert([
            {
              id: teamIdNew,
              game_id: gameId,
              team_id: athletId,
              stage: stage,
            },
          ]);

          if (error) {
            console.error(error);
            swal("Ошибка!", "Не удалось записать победителя", "error");
            return;
          }

          window.location.reload();
        } catch (err) {
          console.error(err);
          swal("Ошибка!", "Произошла ошибка при выборе победителя", "error");
        }
      }
    });
  };

  const allAthletes = teams.some((team) =>
    team.athletes.some((athlete) => athlete?.trim() == "")
  );

  const changedAthletes = (e) => {
    if (e.target.checked) {
      setUseAthletes(true);
    } else {
      setUseAthletes(false);
    }
  };

  const deleteParticipiant = async (team) => {
    const { error: errorGR } = await supabase
      .from("Game_Results")
      .delete()
      .eq("team_id", team.id)
      .eq("game_id", gameId);

    if (errorGR) {
      swal("Ошибка!", "Не удалось удалить команду", "error");
      return;
    }

    const { error: errorPart } = await supabase
      .from("Participants_of_the_game")
      .delete()
      .eq("team_id", team.id)
      .eq("game_id", gameId);

    if (errorPart) {
      swal("Ошибка!", "Не удалось удалить команду", "error");
      return;
    } else {
      window.location.reload();
    }
  };

  const deleteGameResult = async (team, roundIndex) => {
    debugger;
    if (team.id) {
      for (let i = roundIndex; rounds.length >= i; i++) {
        const { error: errorGR } = await supabase
          .from("Game_Results")
          .delete()
          .eq("team_id", team.id)
          .eq("game_id", gameId)
          .eq("stage", i);

        if (errorGR) {
          swal("Ошибка!", "Не удалось удалить команду", "error");
          return;
        }
      }
      window.location.reload();
    }
  };

  const deleteWinner = async (team) => {
    if (team.id) {
      debugger;
      const { error: errorGR } = await supabase
        .from("Game_Results")
        .delete()
        .eq("team_id", team.id)
        .eq("game_id", gameId)
        .eq("stage", rounds.length);

      if (errorGR) {
        swal("Ошибка!", "Не удалось удалить команду", "error");
        return;
      }
      window.location.reload();
    }
  };

  if (loading) {
    return (
      <div className="App">
        <h1 className="discipline-title" style={{ marginBottom: "60px" }}>
          {games.find((game) => game.id == gameId)?.game_name || ""}
        </h1>
        <h1>Загрузка...</h1>
      </div>
    );
  }

  return (
    <div className="App">
      <h1 className="discipline-title" style={{ marginBottom: "60px" }}>
        {games.find((game) => game.id == gameId)?.game_name || ""}
      </h1>
      <div className="bracket-container">
        <table className="bracket">
          <thead>
            <tr>
              {Array.from({ length: Math.log2(games[0].team_count) }).map(
                (_, index) => (
                  <th key={index} style={{ fontSize: "25px" }}>
                    {index === Math.log2(games[0].team_count) - 1
                      ? "Финал"
                      : `Раунд ${index + 1}`}
                  </th>
                )
              )}
              <th style={{ fontSize: "25px" }}>🏆 Победитель</th>
            </tr>
          </thead>
          <tbody>
            {rounds.length > 0 &&
              rounds[0].map((team, index) => (
                <tr key={team.id}>
                  <td>
                    <button
                      className="winner-button"
                      onClick={() => handleWinner(team.id, team.name, 1)}
                    >
                      <span style={{ whiteSpace: "pre-line" }}>
                        {team.name}
                      </span>
                    </button>
                    {isLogged && (
                      <button
                        style={{
                          borderRadius: "5px",
                          background: "#007bff",
                          color: "white",
                          border: "none",
                          height: "2.5vh",
                          width: "auto",
                          cursor: "pointer",
                          margin: "5px",
                        }}
                        onClick={() => deleteParticipiant(team)}
                      >
                        Удалить запись
                      </button>
                    )}
                  </td>
                  {rounds.map((round, roundIndex) => {
                    if (
                      index % Math.pow(2, roundIndex + 1) === 0 &&
                      roundIndex < rounds.length - 1
                    ) {
                      return (
                        <td
                          key={roundIndex}
                          rowSpan={Math.pow(2, roundIndex + 1)}
                        >
                          <button
                            className="winner-button"
                            onClick={() =>
                              handleWinner(
                                rounds[roundIndex + 1]?.[
                                  index / Math.pow(2, roundIndex + 1)
                                ]?.id,
                                rounds[roundIndex + 1]?.[
                                  index / Math.pow(2, roundIndex + 1)
                                ]?.name,
                                roundIndex + 2
                              )
                            }
                          >
                            <span style={{ whiteSpace: "pre-line" }}>
                              {rounds[roundIndex + 1]?.[
                                index / Math.pow(2, roundIndex + 1)
                              ]?.name || ""}
                            </span>
                          </button>
                          {isLogged && (
                            <button
                              style={{
                                borderRadius: "5px",
                                background: "#007bff",
                                color: "white",
                                border: "none",
                                height: "2.5vh",
                                width: "auto",
                                cursor: "pointer",
                                margin: "5px",
                              }}
                              onClick={() =>
                                deleteGameResult(
                                  rounds[roundIndex + 1]?.[
                                    index / Math.pow(2, roundIndex + 1)
                                  ],
                                  roundIndex + 1
                                )
                              }
                            >
                              Удалить запись
                            </button>
                          )}
                        </td>
                      );
                    }
                    return null;
                  })}
                  {index === 0 && (
                    <td rowSpan={Math.pow(2, games[0].team_count)}>
                      <button className="winner-button">
                        <span style={{ whiteSpace: "pre-line" }}>
                          {winnerGame[0]?.[0]?.name || ""}
                        </span>
                      </button>
                      {isLogged && (
                        <button
                          style={{
                            borderRadius: "5px",
                            background: "#007bff",
                            color: "white",
                            border: "none",
                            height: "2.5vh",
                            width: "auto",
                            cursor: "pointer",
                            margin: "5px",
                          }}
                          onClick={() => deleteWinner(winnerGame[0]?.[0])}
                        >
                          Удалить запись
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {isLogged
        ? allAthletes && (
            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <button
                className="apply-button"
                style={{ cursor: "pointer" }}
                onClick={handleOverlayOpen}
              >
                Заявка на участие
              </button>
              <button
                className="apply-button"
                style={{ cursor: "pointer" }}
                onClick={handleApplicationsOpen}
              >
                Полученные заявки
              </button>
            </div>
          )
        : allAthletes && (
            <button
              className="apply-button"
              style={{ cursor: "pointer" }}
              onClick={handleOverlayOpen}
            >
              Заявка на участие
            </button>
          )}

      {isOverlayVisible && (
        <div className="overlay">
          <div className="overlay-content_applications">
            <h2>Заявка на участие</h2>
            <ul className="overlay_ul">
              {athletesAdd.map((athlete, index) => (
                <li key={index} className="overlay_strong">
                  <strong>Спортсмен {index + 1}:</strong>
                  <div>
                    {useAthletes ? (
                      <select
                        className="game-input"
                        value={athlete.name}
                        onChange={(e) =>
                          handleAthleteChange(index, "name", e.target.value)
                        }
                      >
                        <option value="">Выберите спортсмена</option>
                        {athletes.map((athl) => (
                          <option key={athl.id} value={athl.name}>
                            {athl.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <>
                        <input
                          type="text"
                          placeholder="ФИО спортсмена"
                          className="game-input"
                          value={athlete.name}
                          onChange={(e) =>
                            handleAthleteChange(index, "name", e.target.value)
                          }
                        />
                        <input
                          type="number"
                          placeholder="Возраст спортсмена"
                          className="game-input"
                          value={athlete.age}
                          onChange={(e) =>
                            handleAthleteChange(index, "age", e.target.value)
                          }
                        />
                      </>
                    )}
                  </div>
                </li>
              ))}
              {!useAthletes && (
                <li>
                  <div>
                    <strong>Тренер команды: </strong>
                    <input
                      type="text"
                      placeholder="ФИО тренера"
                      className="game-input"
                      value={coach.name}
                      onChange={(e) =>
                        handleCoachChange("name", e.target.value)
                      }
                    />
                    <input
                      type="number"
                      placeholder="Возраст тренера"
                      className="game-input"
                      value={coach.age}
                      onChange={(e) => handleCoachChange("age", e.target.value)}
                    />
                    <input
                      type="number"
                      placeholder="Опыт работы тренера"
                      className="game-input"
                      value={coach.experience}
                      onChange={(e) =>
                        handleCoachChange("experience", e.target.value)
                      }
                    />
                  </div>
                </li>
              )}
            </ul>
            <div>
              <input
                type="checkbox"
                checked={useAthletes}
                onChange={changedAthletes}
              />
              Выбрать из существующих
            </div>
            <div className="overlay_buttons">
              <button className="overlay_button" onClick={handleSubmit}>
                Отправить заявку
              </button>
              <button className="overlay_button" onClick={handleOverlayClose}>
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}

      {isApplicationsVisible && (
        <div className="overlay">
          <div className="overlay-content_applications">
            <h2>Заявки на участие</h2>
            {groupedByCoach.map((coachGroup, index) => (
              <div key={index} className="overlay_ul">
                <hr />
                <h3>Заявка №{index + 1}</h3>
                <div className="overlay_ul">
                  <strong>ФИО тренера: </strong>
                  {coachGroup.coach}
                  <br />
                  <strong>Возраст тренера: </strong>
                  {coachGroup.coach_age} лет
                  <br />
                  <strong>Опыт работы тренера: </strong>
                  {coachGroup.coach_experience} лет
                  <br />
                </div>
                <br />
                <ul className="overlay_ul">
                  {coachGroup.players.map((application, idx) => (
                    <li key={idx} className="overlay_strong">
                      <strong>ФИО спортсмена: </strong>
                      {application.name}
                      <br />
                      <strong>Возраст: </strong>
                      {application.age} лет
                      <br />
                    </li>
                  ))}
                </ul>
                <div className="overlay_buttons">
                  <button onClick={() => Approve(coachGroup)}>Одобрить</button>
                  <button onClick={() => Reject(coachGroup)}>Отклонить</button>
                </div>
              </div>
            ))}
            <hr />
            <button
              className="overlay_button"
              onClick={handleApplicationsClose}
            >
              Закрыть
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Сhampionships;