import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Red from "./Red";
import Table from "./Table";
import Report from "./Report";
import Sports from "./Sports";
import Athletes from "./Athletes";
import Header from "./Header";
import Сhampionships from "./Сhampionships";
import Sport_Stadiums from "./Sport_Stadiums";
import Coaches from "./Coaches";
import LeaderBoard from "./LeaderBoard";
import NotFound from "./NotFound";

function App() {
  return (
    <Router>
    <div className="App">
      <Header/>
        <Routes>
          <Route path="/" element={<Red />} />
          <Route path="/table" element={<Table />} />
          <Route path="/report" element={<Report />} />
          <Route path="/:sport" element={<Sports />} />
          <Route path="/athletes" element={<Athletes />} />
          <Route path="/sport_stadiums" element={<Sport_Stadiums />} />
          <Route path="/coaches" element={<Coaches />} />
          <Route path="/leaderboard" element={<LeaderBoard />} />
          <Route path="/:sport/championships" element={<Сhampionships />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
    </div>
  </Router>
  );
}

export default App;
