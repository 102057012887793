import React, { useState, useEffect } from "react";
import { supabase } from "./Red";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date)) return "Дата не доступна";
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return date.toLocaleDateString("ru-RU", options);
};

const Report = () => {
  const [games, setGames] = useState([]);
  const [games_res, setGames_res] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [stadiums, setStadiums] = useState([]);
  const [loading, setLoading] = useState(true);

  const getParticipantsByGame = (gameId) => {
    const game = games.find((g) => g.id === gameId);
    const teamCount = game ? game.team_count : 0;

    const teams = participants
      .filter((p) => p.game_id === gameId)
      .reduce((acc, curr) => {
        const teamId = curr.team_id;
        const athlete = athletes.find((a) => a.id === curr.athlet_id);
        if (!acc[teamId]) acc[teamId] = [];
        acc[teamId].push(athlete ? athlete.name : "Не определена");
        return acc;
      }, {});

    for (let i = 1; i <= teamCount; i++) {
      if (!teams[i]) {
        teams[i] = ["Не определена"];
      }
    }

    return Object.entries(teams).map(([teamId, members]) => ({
      teamId,
      members,
    }));
  };

  const getGameResult = (gameId) => {
    if (!games_res || games_res.length === 0) return "Итог неизвестен";
    const game = games.find((g) => g.id === gameId);
    const winners = games_res.filter(
      (r) => r.game_id === gameId && r.stage === Math.log2(game.team_count)
    );

    if (winners.length > 0) {
      const teamIds = [
        ...new Set(
          winners
            .map((r) => {
              const participant = participants.find(
                (p) => p.team_id === r.team_id && p.game_id === gameId
              );
              return participant ? participant.team_id : null;
            })
            .filter(Boolean)
        ),
      ];

      return teamIds.length > 0 ? teamIds.join("; ") : "Нет победителя";
    }
    return "Нет победителя";
  };

  const getMostPopularGame = () => {
    if (games.length === 0) return null;
    const gameParticipationCount = games.map((game) => {
      const participantsCount = participants.filter(
        (p) => p.game_id === game.id
      ).length;
      return { game, participantsCount };
    });
    return gameParticipationCount.reduce(
      (max, game) =>
        game.participantsCount > max.participantsCount ? game : max,
      gameParticipationCount[0]
    );
  };
  

  const getLeastPopularGame = () => {
    if (games.length === 0) return null;
    const gameParticipationCount = games.map((game) => {
      const participantsCount = participants.filter(
        (p) => p.game_id === game.id
      ).length;
      return { game, participantsCount };
    });
    return gameParticipationCount.reduce((min, game) =>
      game.participantsCount < min.participantsCount ? game : min
    , gameParticipationCount[0]);
  };
  

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const [game, game_res, athlet, participiant, stadium] =
        await Promise.all([
          supabase.from("Games").select(),
          supabase.from("Game_Results").select(),
          supabase.from("Athletes").select(),
          supabase.from("Participants_of_the_game").select(),
          supabase.from("Stadiums").select(),
        ]);
  
      setGames(game.data);
      setGames_res(game_res.data);
      setAthletes(athlet.data);
      setParticipants(participiant.data);
      setStadiums(stadium.data);
      setLoading(false);
    }
  
    fetch();
  }, []);

  return (
    <div className="App">
      <h1>Отчет по играм</h1>
      <div className="view">
        {loading ? (
          <h1>Загрузка...</h1>
        ) : (
          <>
            <h2>Наиболее популярный чемпионат:</h2>
            <div className="game-card">
              <h2 className="game-title">
                {getMostPopularGame()?.game.game_name}
              </h2>
            </div>

            <h2>Наименее популярный чемпионат:</h2>
            <div className="game-card">
              <h2 className="game-title">
                {getLeastPopularGame()?.game.game_name}
              </h2>
            </div>
            {console.log(games)}
            <h2>Все игры:</h2>
            {games.length > 0 ? (
              games.map((game) => (
                <div key={game.id} className="game-card">
                  <h2 className="game-title">
                    {game.discipline_id}{" "}
                    {game.game_name}
                  </h2>
                  <p className="game-info">
                    <strong>Дата:</strong> {formatDate(game.game_date_from)} -{" "}
                    {formatDate(game.game_date_to)}
                  </p>

                  <p className="game-info">
                  <strong>Стадион:</strong> {stadiums.find(s => s.id === game.stadium_id)?.name || "Неизвестный"}
                  </p>
                  <div className="team-info">
                    <strong>Участники:</strong>
                    {getParticipantsByGame(game.id).map((team) => (
                      <div key={team.teamId}>
                        <p>
                          <strong>Команда {team.teamId}:</strong>
                        </p>
                        <ul style={{ listStyle: "none" }}>
                          {team.members.map((member, index) => (
                            <li key={index}>{member}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                  <div className="game-result">
                    <h2 className="discipline-title">Результат игры: </h2>
                    <p>
                      <strong>Победа команды:</strong> №{getGameResult(game.id)}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <h2>Результаты не найдены</h2>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Report;