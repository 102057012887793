import React, { useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "./Red";
import { useState } from "react";
import "./App.css";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import { AuthWeakPasswordError } from "@supabase/supabase-js";
const cookies = new Cookies();

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).toLocaleDateString("ru-RU", options);
};

function Sports() {
  const [edit, setEdit] = useState(false);
  const [searchGame, setSearchGame] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(true);
  const [sports, setSports] = useState(null);
  const [games, setGames] = useState(null);
  const [stadiums, setStadiums] = useState(null);
  const { sport } = useParams();
  const navigate = useNavigate();

  const [editGame, setEditGame] = useState({
    id: "",
    game_name: "",
    stadium_id: "",
    sport_id: "",
    game_date_from: "",
    game_date_to: "",
    team_count: "",
    team_number: "",
  });

  const searchGames = games?.filter(
    (game) =>
      game.game_name.toLowerCase().includes(searchGame.toLowerCase()) && game.sport_id == sports.id
  );

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const { data: sportData } = await supabase.from("Sports").select();
      const sportExists = sportData.find(
        (item) => item.name.toLowerCase() === sport.toLowerCase()
      );
      if (!sportExists) {
        setLoading(false);
        navigate("/");
      }
      setSports(sportExists);
      const { data: games } = await supabase.from("Games").select();
      setGames(games);
      const { data: stadium } = await supabase.from("Stadiums").select();
      setStadiums(stadium);
      setLoading(false);
    }

    if (cookies.get("logged") == true) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }

    fetch();
  }, []);

  const addGame = async () => {
    debugger
    const name = document.getElementById("gameName").value;
    const stadium = document.getElementById("stadium_id").value;
    const datefrom = document.getElementById("gameDatefrom").value;
    const dateto = document.getElementById("gameDateto").value;
    const teamcount = document.getElementById("teamCount").value;
    const teamnum = document.getElementById("teamNumber").value;

    if (!name || !stadium || !datefrom || !dateto || !teamcount || !teamnum || teamnum < 1) {
      swal("Ошибка!", "Поля не заполнены", "error");
      return;
    }
    if(teamcount < 2){
      swal("Ошибка!", "Количество команд не может быть меньше 2", "error");
      return;
    }
    if(teamnum < 1){
      swal("Ошибка!", "Количество участников не может быть меньше 1", "error");
      return;
    }

    const currentYear = new Date().getFullYear();
    const gameDatefrom = new Date(datefrom).getFullYear();
    const gameDateto = new Date(dateto).getFullYear();

    if (datefrom > dateto) {
      swal("Ошибка!", "Дата окончания раньше даты начала", "error");
      return;
    } else if (gameDatefrom < currentYear || gameDateto > gameDatefrom + 1) {
      swal("Ошибка!", "Даты сильно разные или дата начала старая", "error");
      return;
    }
    const isPowTwo = Math.log2(teamcount) % 1 == 0
    if (!isPowTwo && teamcount != 2) {
      swal(
        "Ошибка!",
        "Количество команд должно быть степенью двойки",
        "error"
      );
      return;
    }

    const useStadium = games.some(
      (game) =>
        game.stadium_id === Number(stadium) &&
        !(
          new Date(game.game_date_to) < new Date(datefrom) ||
          new Date(game.game_date_from) > new Date(dateto)
        )
    );
    console.log(useStadium);

    if (useStadium) {
      swal("Ошибка!", "Спортивный комплекс занят в эти даты", "error");
      return;
    }

     const { data: lastCoach } = await supabase
              .from("Games")
              .select("id")
              .order("id", { ascending: false })
              .limit(1);

    const { error } = await supabase.from("Games").insert([
      {
        id: lastCoach[0].id + 1,
        game_name: name,
        stadium_id: stadium,
        sport_id: sports.id,
        game_date_from: datefrom,
        game_date_to: dateto,
        team_count: teamcount,
        team_number: teamnum,
      },
    ]);
    if (error) {
      console.log(error);
      swal("Ошибка!", "Ошибка добавления игры", "error");
    } else {
      window.location.reload();
    }
  };

  async function editGameDetails() {
    console.log(editGame);
    if (
      !editGame.game_name ||
      !editGame.stadium_id ||
      !editGame.game_date_from ||
      !editGame.game_date_to ||
      !editGame.team_count ||
      !editGame.team_number
    ) {
      swal("Ошибка!", "Поля не заполнены", "error");
      return;
    }
    debugger
    if(editGame.team_count < 2){
      swal("Ошибка!", "Количество команд не может быть меньше 2", "error");
      return;
    }
    if(editGame.team_number < 1){
      swal("Ошибка!", "Количество участников не может быть меньше 1", "error");
      return;
    }

    const currentYear = new Date().getFullYear();
    const gameDatefrom = new Date(editGame.game_date_from).getFullYear();
    const gameDateto = new Date(editGame.game_date_to).getFullYear();

    if (editGame.game_date_from > editGame.game_date_to) {
      swal("Ошибка!", "Дата окончания раньше даты начала", "error");
      return;
    } else if (gameDatefrom < currentYear || gameDateto > gameDatefrom + 1) {
      swal("Ошибка!", "Даты сильно разные или дата начала старая", "error");
      return;
    }
    const isPowTwo = Math.log2(editGame.team_count) % 1 == 0
    if (!isPowTwo && editGame.team_count != 2) {
      swal(
        "Ошибка!",
        "Количество команд должно быть степенью двойки",
        "error"
      );
      return;
    }

    const useStadium = games.some(
      (game) =>
        game.stadium_id === Number(editGame.stadium_id) &&
        !(
          new Date(game.game_date_to) < new Date(editGame.game_date_from) ||
          new Date(game.game_date_from) > new Date(editGame.game_date_to)
        ) &&
        game.id !== editGame.id
    );
    console.log(useStadium);

    if (useStadium) {
      swal("Ошибка!", "Спортивный комплекс занят в эти даты", "error");
      return;
    }
    
    const { error } = await supabase.from("Games").update([
      {
        game_name: editGame.game_name,
        stadium_id: editGame.stadium_id,
        sport_id: sports.id,
        game_date_from: editGame.game_date_from,
        game_date_to: editGame.game_date_to,
        team_count: editGame.team_count,
        team_number: editGame.team_number,
      },
    ]).eq("id", editGame.id);

    if (error) {
      console.log(error);
      swal("Ошибка!", "Ошибка редактирования игры", "error");
    } else {
      window.location.reload();
    }
  }

  const deleteGame = async (game_id) => {
    debugger
    const {error: errorApp} = await supabase.from("Applications_of_athletes").delete().eq("game_id",game_id)
    if (errorApp) {
      console.log(errorApp);
      swal("Ошибка!", "Ошибка удаления игры", "error");
    }
    const {error: errorPart} = await supabase.from("Participants_of_the_game").delete().eq("game_id", game_id)
    if (errorPart) {
      console.log(errorPart);
      swal("Ошибка!", "Ошибка удаления игры", "error");
    }
    const {error: errorGR} = await supabase.from("Game_Results").delete().eq("game_id", game_id)
    if (errorGR) {
      console.log(errorGR);
      swal("Ошибка!", "Ошибка удаления игры", "error");
    }
    const { error } = await supabase.from("Games").delete().eq("id", game_id);
    if (error) {
      console.log(error);
      swal("Ошибка!", "Ошибка удаления игры", "error");
    } else {
      window.location.reload();
    }
  };
  
  return (
    <div className="App">
      <h1 id="games" className="games-header">
        {sport.charAt(0).toUpperCase() + sport.slice(1)}
      </h1>
      <input
        type="search"
        placeholder="Поиск"
        className="search-input"
        onChange={(e) => setSearchGame(e.target.value)}
      />

      <div className="view">
        {loading ? (
          <h1>Загрузка...</h1>
        ) : searchGames.length === 0 ? (
          isLogged ? (
            <div className="game-card" style={{ gap: "10px" }}>
              <h2 className="form-title">Добавить чемпионат</h2>
              <input
                id="gameName"
                className="game-input"
                placeholder="Название чемпионата"
              />
              <select className="game-input" id="stadium_id">
                <option value="">Выберите спортивный комплекс</option>
                {stadiums.map((stadium) => (
                  <option key={stadium.id} value={stadium.id}>
                    {stadium.name}
                  </option>
                ))}
              </select>
              <strong className="form-title">Сроки игры</strong>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <strong>С: </strong>
                <input type="date" className="game-input" id="gameDatefrom" />
              </div>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <strong>По: </strong>
                <input type="date" className="game-input" id="gameDateto" />
              </div>
              <input
                id="teamCount"
                className="game-input"
                type="number"
                placeholder="Количество команд"
              />
              <input
                id="teamNumber"
                type="number"
                className="game-input"
                placeholder="Количество спортсменов в команде"
              />
              <button className="add-game-button" onClick={addGame}>
                Добавить
              </button>
            </div>
          ) : (
            <h2>Информация об играх для этого вида спорта отсутствует.</h2>
          )
        ) : searchGames.length > 0 ? (
          <div className="game-buttons">
            {searchGames.map((game) => (
              <div
                className="game-card"
                style={{ cursor: !edit ? "pointer" : "default" }}
                onClick={(e) => {
                  if (!edit) {
                    navigate(
                      `/${sport.toLowerCase()}/championships?gameid=${game.id}`
                    );
                  }
                }}
              >
                {edit && editGame?.id === game.id ? (
                  isLogged && (
                    <>
                      <h2 className="form-title">
                        Редактирование чемпионата:{" "}
                      </h2>
                      <input
                        id="gameName"
                        className="game-input"
                        defaultValue={editGame.game_name}
                        placeholder="Название чемпионата"
                        onChange={(e) =>
                          setEditGame({
                            ...editGame,
                            game_name: e.target.value,
                          })
                        }
                      />
                      <select
                        className="game-input"
                        id="stadium_id"
                        defaultValue={editGame.stadium_id}
                        onChange={(e) =>
                          setEditGame({
                            ...editGame,
                            stadium_id: e.target.value,
                          })
                        }
                      >
                        <option value="">Выберите спортивный комплекс</option>
                        {stadiums.map((stadium) => (
                          <option key={stadium.id} value={stadium.id}>
                            {stadium.name}
                          </option>
                        ))}
                      </select>
                      <strong className="form-title">Сроки игры</strong>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <strong>С: </strong>
                        <input
                          type="date"
                          className="game-input"
                          defaultValue={editGame.game_date_from}
                          onChange={(e) =>
                            setEditGame({
                              ...editGame,
                              game_date_from: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <strong>По: </strong>
                        <input
                          type="date"
                          className="game-input"
                          defaultValue={editGame.game_date_to}
                          onChange={(e) =>
                            setEditGame({
                              ...editGame,
                              game_date_to: e.target.value,
                            })
                          }
                        />
                      </div>
                      <input
                        className="game-input"
                        type="number"
                        defaultValue={editGame.team_count}
                        placeholder="Количество команд"
                        onChange={(e) =>
                          setEditGame({
                            ...editGame,
                            team_count: e.target.value,
                          })
                        }
                      />
                      <input
                        type="number"
                        className="game-input"
                        defaultValue={editGame.team_number}
                        placeholder="Количество спортсменов в команде"
                        onChange={(e) =>
                          setEditGame({
                            ...editGame,
                            team_number: e.target.value,
                          })
                        }
                      />
                      <div className="game-item-actions">
                        <button
                          className="edit-game-button"
                          onClick={editGameDetails}
                        >
                          Сохранить
                        </button>
                        <button
                          className="edit-game-button"
                          onClick={() => {
                            setEdit(false);
                            setEditGame(null);
                          }}
                        >
                          Отменить
                        </button>
                      </div>
                    </>
                  )
                ) : (
                  <>
                    <h2 className="game-title">{game.game_name}</h2>
                    <h2 className="info">Сроки игры: </h2>
                    <p className="info">
                      {formatDate(game.game_date_from)} -{" "}
                      {formatDate(game.game_date_to)}
                    </p>
                    <p className="info">
                      <strong>Спортивный комплекс: </strong>{" "}
                      {stadiums.find((e) => e.id == game.stadium_id)?.name ||
                        "Неизвестно"}
                    </p>
                    <p className="info">
                      <strong>Количество команд: </strong>
                      {game.team_count}
                    </p>
                    <p className="info">
                      <strong>Количество человек в команде: </strong>{" "}
                      {game.team_number}
                    </p>
                    {isLogged && (
                      <div className="game-item-actions">
                        <button
                          className="edit-game-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEdit(true);
                            setEditGame(game);
                          }}
                        >
                          Изменить
                        </button>
                        <button
                          className="delete-game-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteGame(game.id);
                          }}
                        >
                          Удалить
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
            {isLogged && (
              <div className="game-card">
                <h2 className="form-title">Добавить чемпионат</h2>
                <input
                  id="gameName"
                  className="game-input"
                  placeholder="Название чемпионата"
                />
                <select className="game-input" id="stadium_id">
                  <option value="">Выберите спортивный комплекс</option>
                  {stadiums.map((stadium) => (
                    <option key={stadium.id} value={stadium.id}>
                      {stadium.name}
                    </option>
                  ))}
                </select>
                <strong className="form-title">Сроки игры</strong>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <strong>С: </strong>
                  <input type="date" className="game-input" id="gameDatefrom" />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <strong>По: </strong>
                  <input type="date" className="game-input" id="gameDateto" />
                </div>
                <input
                  id="teamCount"
                  className="game-input"
                  type="number"
                  placeholder="Количество команд"
                />
                <input
                  id="teamNumber"
                  type="number"
                  className="game-input"
                  placeholder="Количество спортсменов в команде"
                />
                <button className="add-game-button" onClick={addGame}>
                  Добавить
                </button>
              </div>
            )}
          </div>
        ) : (
          <h2>Результаты не найдены</h2>
        )}
      </div>
    </div>
  );
}

export default Sports;