import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "./Red";
import { useState } from "react";
import "./App.css";
import swal from "sweetalert";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).toLocaleDateString("ru-RU", options);
};

function Sport_Stadiums() {
  const [stadiums, setStadiums] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editStadium, setEditStadium] = useState(null);
  const [searchStadium, setSearchStadium] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(true);
  const [games, setGames] = useState([]);

  const searchStadiums = stadiums.filter((stadium) =>
    stadium.name.toLowerCase().includes(searchStadium.toLowerCase())
  );

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const { data, error } = await supabase.from("Stadiums").select(`
                id,
                capacity,
                name,
                location
              `);

      if (error) {
        console.error(error);
        setLoading(false);
        return;
      }
      const {data: dataGames} = await supabase.from("Games").select()
      setGames(dataGames);
      setStadiums(data);
      setLoading(false);
    }

    if (cookies.get("logged") == true) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
    fetch();
  }, []);

  const addStadium = async () => {
    const name = document.getElementById("stadiumName"); 
    const capacity = document.getElementById("stadiumCapacity");
    const location = document.getElementById("stadiumLocation");

    if (!name || !capacity || !location) {
      swal("Ошибка!", "Введите корректные данные", "error");
      return;
    }
    else if(capacity.value < 100){
      swal("Ошибка!", "Вместимость не может быть меньше 100", "error");
      return;
    }

    const { data: dataid, error: errorid } = await supabase
      .from("Stadiums")
      .select("id")
      .order("id", { ascending: false })
      .limit(1);

    if (errorid) {
      console.log(errorid);
      swal("Ошибка!", "Не удалось добавить спортивный комплекс", "error");
    }

    const { error } = await supabase
      .from("Stadiums")
      .insert([
        {
          id: dataid[0].id + 1,
          capacity: capacity.value,
          name: name.value,
          location: location.value
        },
      ])
      .select();

    if (error) {
      console.error(error);
      swal("Ошибка!", "Не удалось добавить спортивный комплекс", "error");
      return;
    }

    window.location.reload();
  };

  async function editStadiumDetails() {
    if (
      !editStadium.name ||
      !editStadium.capacity ||
      editStadium.capacity < 100 ||
      !editStadium.location
    ) {
      swal("Ошибка!", "Введите корректные данные", "error");
      return;
    } else if (editStadium.value < 100) {
      swal("Ошибка!", "Вместимость не может быть меньше 100", "error");
      return;
    }
    const { error } = await supabase
      .from("Stadiums")
      .update({
        name: editStadium.name,
        capacity: editStadium.capacity,
        location: editStadium.location,
      })
      .eq("id", editStadium.id);

    if (error) {
      console.error(error);
      swal("Ошибка!", "Не удалось изменить спортивный комплекс", "error");
      return;
    }

    window.location.reload();
  }

  async function deleteStadium(id) {
    const { error } = await supabase.from("Stadiums").delete().eq("id", id);

    if (error) {
      if (error.code == 23503) {
        swal("Ошибка!", "Удалите чемпионаты с этим спортивным комплексом", "error");
      } else {
        console.error(error);
        swal("Ошибка!", "Не удалось удалить спортивный комплекс", "error");
      }
    } else {
      window.location.reload();
    }
  }

  const stadiumGames = (stadium) => {
    return games
      .filter((g) => g.stadium_id === stadium.id)
      .sort((a, b) => new Date(a.game_date_from) - new Date(b.game_date_from));
  };
  

  return (
    <div className="App">
      <h1 id="games" className="games-header">
        Спортивные комплексы
      </h1>
      <input
        type="search"
        placeholder="Поиск"
        className="search-input"
        onChange={(e) => setSearchStadium(e.target.value)}
      />

      <div className="view">
        {loading ? (
          <h1>Загрузка...</h1>
        ) : searchStadiums.length > 0 ? (
          <div className="game-buttons">
            {searchStadiums.map((stadium) => (
              <div className="game-card">
                {edit && editStadium?.id === stadium.id ? (
                  isLogged && (
                    <>
                      <h2 className="form-title">
                        <strong>Название спортивного комплекса:</strong>
                        <input
                          value={editStadium.name}
                          className="game-input"
                          onChange={(e) =>
                            setEditStadium({
                              ...editStadium,
                              name: e.target.value,
                            })
                          }
                        />
                      </h2>
                      <div style={{ display: "flex" }}>
                        <p className="info">
                          <strong>Вместимость: </strong>
                        </p>
                        <input
                          className="game-input"
                          type="number"
                          defaultValue={stadium.capacity}
                          placeholder="Вместимость спортивного комплекса"
                          onChange={(e) =>
                            setEditStadium({
                              ...editStadium,
                              capacity: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <p className="info">
                          <strong>Местоположение: </strong>
                        </p>
                        <input
                          id="stadiumLocaton"
                          className="game-input"
                          defaultValue={stadium.location}
                          placeholder="Местоположение спортивного комплекса"
                          onChange={(e) =>
                            setEditStadium({
                              ...editStadium,
                              location: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="game-item-actions">
                        <button
                          className="edit-game-button"
                          onClick={editStadiumDetails}
                        >
                          Сохранить
                        </button>
                        <button
                          className="edit-game-button"
                          onClick={() => {
                            setEdit(false);
                            setEditStadium(null);
                          }}
                        >
                          Отменить
                        </button>
                      </div>
                    </>
                  )
                ) : (
                  <>
                    <div style={{display:"flex", flexDirection:"column", height:"100%", justifyContent:'start'}}>
                      <h2 className="game-title">{stadium.name}</h2>
                      <p className="game-info">
                        <strong>Вместимость: </strong>
                        {stadium.capacity} чел.
                      </p>
                      <p className="game-info">
                        <strong>Местоположение: </strong>
                        {stadium.location}
                      </p>
                      {stadiumGames(stadium).length > 0 && (
                        <>
                          <h3>Занятые периоды:</h3>
                          {stadiumGames(stadium).map((game, index) => (
                            <p key={index} className="game-info">
                                {" "}
                                {formatDate(game.game_date_from)}{" "}
                                -{" "}
                                {formatDate(game.game_date_to)}
                            </p>
                          ))}
                        </>
                      )}
                      {isLogged && (
                        <div className="game-item-actions" style={{marginTop:"auto"}}>
                          <button
                            className="edit-game-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEdit(true);
                              setEditStadium(stadium);
                            }}
                          >
                            Изменить
                          </button>
                          <button
                            className="delete-game-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteStadium(stadium.id);
                            }}
                          >
                            Удалить
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            ))}
            {isLogged && (
              <div className="game-card">
                <h2 className="form-title">Добавить спортивный комплекс</h2>
                <input
                  id="stadiumName"
                  className="game-input"
                  placeholder="Название спортивного комплекса"
                />
                <input
                  id="stadiumCapacity"
                  className="game-input"
                  type="number"
                  placeholder="Вместимость спортивного комплекса"
                />
                <input
                  id="stadiumLocation"
                  className="game-input"
                  placeholder="Местоположение спортивного комплекса"
                />
                <button className="add-game-button" onClick={addStadium}>
                  Добавить
                </button>
              </div>
            )}
          </div>
        ) : (
          <h2>Результаты не найдены</h2>
        )}
      </div>
    </div>
  );
}

export default Sport_Stadiums;