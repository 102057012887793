import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "./Red";
import { useState } from "react";
import "./App.css";
import swal from "sweetalert";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Coaches = () => {
  const [coaches, setCoaches] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editCoach, setEditCoach] = useState(null);
  const [searchCoach, setSearchCoach] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(true);

  const searchCoaches = coaches.filter((coach) =>
    coach.name.toLowerCase().includes(searchCoach.toLowerCase())
  );

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const { data, error } = await supabase.from("Coaches").select(`
                    id,
                    name,
                    age,
                    experience
                  `);

      if (error) {
        console.error(error);
        setLoading(false);
        return;
      }
      setCoaches(data);
      setLoading(false);
    }

    if (cookies.get("logged") == true) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
    fetch();
  }, []);

  const addCoach = async () => {
    const name = document.getElementById("coachName");
    const age = document.getElementById("coachAge");
    const experience = document.getElementById("coachExperience");
    const check = age.value - experience.value;

    if (!name || !age || age.value < 18 || !experience || experience.value < 0 || age.value > 80 || check < 18) {
      swal("Ошибка!", "Введите корректные данные", "error");
      return;
    }

    const { data: dataid, error: errorid } = await supabase
      .from("Coaches")
      .select("id")
      .order("id", { ascending: false })
      .limit(1);

    if (errorid) {
      console.log(errorid);
      swal("Ошибка!", "Не удалось добавить тренера", "error");
    }

    const { error } = await supabase
      .from("Coaches")
      .insert([
        {
          id: dataid[0].id + 1,
          age: age.value,
          name: name.value,
          experience: experience.value,
        },
      ])
      .select();

    if (error) {
      console.error(error);
      swal("Ошибка!", "Не удалось добавить тренера", "error");
      return;
    }

    window.location.reload();
  };

  async function editCoachDetails() {
    const check = editCoach.age - editCoach.experience;
    if (
      !editCoach.name ||
      !editCoach.age ||
      editCoach.age < 18 ||
      !editCoach.experience ||
      editCoach.experience < 0 ||
      editCoach.age > 80 ||
      check < 18
    ) {
      swal("Ошибка!", "Введите корректные данные", "error");
      return;
    }
    const { error } = await supabase
      .from("Coaches")
      .update({
        name: editCoach.name,
        age: editCoach.age,
        experience: editCoach.experience,
      })
      .eq("id", editCoach.id);

    if (error) {
      console.error(error);
      swal("Ошибка!", "Не удалось изменить тренера", "error");
      return;
    }

    window.location.reload();
  }

  async function deleteCoach(id) {
    const { error } = await supabase.from("Coaches").delete().eq("id", id);

    if (error) {
      if (error.code == 23503) {
        swal("Ошибка!", "Удалите спортсмена с этим тренером ", "error");
      } else {
        console.error(error);
        swal("Ошибка!", "Не удалось удалить тренера", "error");
      }
   
    } else {
      window.location.reload();
    }
  }

  return (
    <div className="App">
      <h1 id="games" className="games-header">
        Тренеры
      </h1>
      <input
        type="search"
        placeholder="Поиск"
        className="search-input"
        onChange={(e) => setSearchCoach(e.target.value)}
      />

      <div className="view">
        {loading ? (
          <h1>Загрузка...</h1>
        ) : searchCoaches.length > 0 ? (
          <div className="game-buttons">
            {searchCoaches.map((coach) => (
              <div className="stad-item game-form">
                {edit && editCoach?.id === coach.id ? (
                  isLogged && (
                    <>
                      <h2 className="form-title">
                        <strong>ФИО тренера:</strong>
                        <input
                          value={editCoach.name}
                          className="game-input"
                          onChange={(e) =>
                            setEditCoach({
                              ...editCoach,
                              name: e.target.value,
                            })
                          }
                        />
                      </h2>
                      <div style={{ display: "flex" }}>
                        <p className="info">
                          <strong>Возраст: </strong>
                        </p>
                        <input
                          className="game-input"
                          type="number"
                          defaultValue={coach.age}
                          placeholder="Возраст тренера"
                          onChange={(e) =>
                            setEditCoach({
                              ...editCoach,
                              age: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <p className="info" style={{whiteSpace:"nowrap"}}>
                          <strong>Опыт работы: </strong>
                        </p>
                        <input
                          className="game-input"
                          defaultValue={coach.experience}
                          placeholder="Опыт работы тренера"
                          onChange={(e) =>
                            setEditCoach({
                              ...editCoach,
                              experience: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="game-item-actions">
                        <button
                          className="edit-game-button"
                          onClick={editCoachDetails}
                        >
                          Сохранить
                        </button>
                        <button
                          className="edit-game-button"
                          onClick={() => {
                            setEdit(false);
                            setEditCoach(null);
                          }}
                        >
                          Отменить
                        </button>
                      </div>
                    </>
                  )
                ) : (
                  <>
                    <div style={{ textAlign: "center"}}>
                      <h2 className="game-title">{coach.name}</h2>
                      <p className="game-info">
                        <strong>Возраст: </strong>
                        {coach.age} лет
                      </p>
                      <p className="game-info">
                        <strong>Опыт работы: </strong>
                        {coach.experience} лет
                      </p>
                      {isLogged && (
                        <div className="game-item-actions">
                          <button
                            className="edit-game-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEdit(true);
                              setEditCoach(coach);
                            }}
                          >
                            Изменить
                          </button>
                          <button
                            className="delete-game-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteCoach(coach.id);
                            }}
                          >
                            Удалить
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            ))}
            {isLogged && (
              <div className="stad-item game-form">
                <h2 className="form-title">Добавить тренера</h2>
                <input
                  id="coachName"
                  className="game-input"
                  placeholder="ФИО тренера"
                />
                <input
                  id="coachAge"
                  className="game-input"
                  type="number"
                  placeholder="Возраст тренера"
                />
                <input
                  id="coachExperience"
                  className="game-input"
                  placeholder="Опыт работы тренера"
                />
                <button className="add-game-button" onClick={addCoach}>
                  Добавить
                </button>
              </div>
            )}
          </div>
        ) : (
          <h2>Результаты не найдены</h2>
        )}
      </div>
    </div>
  );
};

export default Coaches;
