import React, { useEffect, useState } from "react";
import "./App.css";
import { supabase } from "./Red";
import swal from "sweetalert";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Athletes = () => {
  const [athletes, setAthletes] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [searchAthlete, setSearchAthlete] = useState("");
  const [edit, setEdit] = useState(false);
  const [editAthlete, setEditAthlete] = useState(null);
  const [selectedCoach, setSelectedCoach] = useState("");
  const [isLogged, setIsLogged] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const { data: athletesData, error: athletesError } =
          await supabase.from("Athletes").select(`
            id,
            age,
            name,
            coach:Coaches!Athletes_coach_id_fkey(id, name)
          `);

        const { data: coachesData, error: coachesError } = await supabase.from(
          "Coaches"
        ).select(`
            id,
            age,
            name,
            experience
          `);

        if (athletesError)
          console.error("Ошибка загрузки спортсменов:", athletesError);
        if (coachesError)
          console.error("Ошибка загрузки тренеров:", coachesError);

        setAthletes(athletesData || []);
        setCoaches(coachesData || []);
        setLoading(false);
      } catch (error) {
        console.error("Ошибка загрузки данных:", error);
      }
    }
    if (cookies.get("logged") == true) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
    fetchData();
  }, []);

  const filteredAthletes = athletes.filter((athlete) =>
    athlete.name.toLowerCase().includes(searchAthlete.toLowerCase())
  );

  async function addAthlet(name, age, coachId) {
    if (!name || !age || !coachId || age < 18 || age > 80) {
      swal("Ошибка", "Проверьте введенные данные", "error");
      return;
    }

    try {
      const { data: dataid, error: errorid } = await supabase
        .from("Athletes")
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      if (errorid) {
        console.error("Ошибка получения последнего ID:", errorid);
        return;
      }

      const newId = dataid?.[0]?.id ? dataid[0].id + 1 : 1;

      const { error } = await supabase
        .from("Athletes")
        .insert([
          {
            id: newId,
            name: name,
            age: age,
            coach_id: coachId,
          },
        ])
        .select();

      if (error) {
        console.error("Ошибка добавления спортсмена:", error);
        swal("Ошибка", "Не удалось добавить спортсмена", "error");
      } else {
        window.location.reload();
      }
    } catch (e) {
      console.error("Ошибка при добавлении спортсмена:", e);
    }
  }

  async function EditAthlet(id, updatedAthlete, coachId) {
    try {
      if (
        !updatedAthlete.name ||
        !updatedAthlete.age ||
        updatedAthlete.age < 18 ||
        updatedAthlete.age > 80
      ) {
        swal("Ошибка", "Проверьте введенные данные", "error");
        return;
      }

      const selectedCoachId =
        coachId || athletes.find((athlete) => athlete.id === id)?.coach?.id;

      if (!selectedCoachId) {
        swal("Ошибка", "Необходимо выбрать тренера", "error");
        return;
      }

      const { error } = await supabase
        .from("Athletes")
        .update({
          name: updatedAthlete.name,
          age: updatedAthlete.age,
          coach_id: selectedCoachId,
        })
        .eq("id", id);

      if (error) {
        console.error("Ошибка редактирования:", error);
        swal("Ошибка", "Не удалось сохранить изменения", "error");
      } else {
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
      swal("Ошибка", "Произошла непредвиденная ошибка", "error");
    }
  }

  async function DeleteEntry(id) {
    try {
      const { error: errorPart } = await supabase
        .from("Participants_of_the_game")
        .delete()
        .eq("athlet_id", id);
      if (errorPart) {
        console.error(errorPart);
        swal("Ошибка!", "Не удалось удалить спортсмена", "error");
      }
      const { error } = await supabase.from("Athletes").delete().eq("id", id);
      if (error) {
        console.error(error);
        swal("Ошибка!", "Не удалось удалить спортсмена", "error");
      } else {
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div className="App">
      <h1 className="h1tittle" id="athletes">
        Спортсмены
      </h1>
      <input
        type="search"
        className="search-input"
        placeholder="Поиск"
        onChange={(e) => setSearchAthlete(e.target.value)}
      />
      <div className="view">
        {loading ? (
          <h1>Загрузка...</h1>
        ) : filteredAthletes.length > 0 ? (
          <>
            <div className="game-buttons">
              {filteredAthletes.map((athlete) => (
                <div className="card" key={athlete.id}>
                  {edit && editAthlete?.id === athlete.id ? (
                    <>
                      <div style={{ margin: "0px", padding: "0px" }}>
                        <h2 className="tittle">
                          <strong>Фио спортсмена:</strong>{" "}
                          <input
                            defaultValue={athlete.name}
                            className="game-input-small"
                            onChange={(e) =>
                              setEditAthlete({
                                ...editAthlete,
                                name: e.target.value,
                              })
                            }
                          />
                        </h2>
                        <p className="info">
                          <strong>Возраст спортсмена:</strong>{" "}
                          <input
                            type="number"
                            className="game-input-small"
                            defaultValue={athlete.age}
                            onChange={(e) =>
                              setEditAthlete({
                                ...editAthlete,
                                age: e.target.value,
                              })
                            }
                          />
                        </p>
                        <p className="info">
                          <strong>Тренер:</strong>{" "}
                          <select
                            defaultValue={athlete.coach.id}
                            className="game-input"
                            onChange={(e) => setSelectedCoach(e.target.value)}
                          >
                            <option value="">Выберите тренера</option>
                            {coaches.map((coach) => (
                              <option key={coach.id} value={coach.id}>
                                {coach.name}
                              </option>
                            ))}
                          </select>
                        </p>
                        <div className="card_button">
                          <button
                            className="supp_button"
                            onClick={() => {
                              EditAthlet(
                                editAthlete.id,
                                editAthlete,
                                selectedCoach
                              );
                              setEdit(false);
                              setEditAthlete(null);
                            }}
                          >
                            Сохранить
                          </button>
                          <button
                            className="supp_button"
                            onClick={() => {
                              setEdit(false);
                              setEditAthlete(null);
                            }}
                          >
                            Отменить
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <h2 className="tittle">{athlete.name}</h2>
                      <p className="info">
                        <strong>Возраст:</strong> {athlete.age} лет
                      </p>
                      <p className="info">
                        <strong>Тренер:</strong> {athlete.coach.name}
                      </p>
                      {isLogged && (
                        <div className="card_button">
                          <button
                            className="supp_button"
                            onClick={() => {
                              setEdit(true);
                              setEditAthlete(athlete);
                            }}
                          >
                            Изменить
                          </button>
                          <button
                            className="supp_button"
                            onClick={() => DeleteEntry(athlete.id)}
                          >
                            Удалить
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}

              {isLogged && (
                <div className="card" style={{alignItems:"center", height:"155px"}}>
                  <h2 className="form-title">Добавить спортсмена</h2>
                    <input id="athletname" className="game-input" placeholder="ФИО спортсмена"/>
                    <input
                      id="athletage"
                      type="number"
                      placeholder="Возраст спортсмена"
                      className="game-input"
                    />            
                    <select
                      defaultValue={athletes.coach?.id || ""}
                      className="game-input"
                      onChange={(e) => setSelectedCoach(e.target.value || null)}
                    >
                      <option value="">Выберите тренера</option>
                      {coaches.map((coach) => (
                        <option key={coach.id} value={coach.id}>
                          {coach.name}
                        </option>
                      ))}
                    </select>
                  <div className="card_button">
                    <button
                      className="supp_button"
                      onClick={() => {
                        const name =
                          document.getElementById("athletname").value;
                        const age = document.getElementById("athletage").value;
                        addAthlet(name, age, selectedCoach);
                      }}
                    >
                      Добавить
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <h2>Результаты не найдены</h2>
        )}
      </div>
    </div>
  );
};

export default Athletes;
