import React, { useEffect, useMemo } from "react";
import { supabase } from "./Red";
import { useState } from "react";
import "./App.css";

function LeaderBoard() {
  const [gameResults, setGameResults] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [loading, setLoading] = useState([]);
  const [participant, setParticipants] = useState([]);
  const [games, setGames] = useState([]);
  const [searchAthlete, setSearchAthlete] = useState("");

  const filteredAthletes = athletes.filter((athlete) =>
    athlete.name.toLowerCase().includes(searchAthlete.toLowerCase())
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const { data: dataParticipants_of_the_game } = await supabase
          .from("Participants_of_the_game")
          .select();
        setParticipants(dataParticipants_of_the_game);

        const { data: athleteData, error: athleteError } = await supabase
          .from("Athletes")
          .select();

        if (athleteError) throw athleteError;
        setAthletes(athleteData);

        const { data: gameResultData, error: gameResultError } = await supabase
          .from("Game_Results")
          .select();

        if (gameResultError) throw gameResultError;
        setGameResults(gameResultData);

        const { data: dataGames } = await supabase.from("Games").select();
        setGames(dataGames);

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const leaderBoard = useMemo(() => {
    const winsByAthlete = {};
    let maxStage = 0;

    gameResults.forEach((result) => {
      const game = games.find((g) => g.id === result.game_id);
      if (game) {
        maxStage = Math.log2(game.team_count);

        if (maxStage === result.stage) {
          const teamParticipants = participant.filter(
            (p) => p.team_id === result.team_id && p.game_id === result.game_id
          );

          teamParticipants.forEach((p) => {
            if (!winsByAthlete[p.athlet_id]) {
              winsByAthlete[p.athlet_id] = { id: p.athlet_id, wins: 0 };
            }
            winsByAthlete[p.athlet_id].wins += 1;
          });
        }
      }
    });

    const uniqueAthletes = new Map();

    participant.forEach((p) => {
      if (!uniqueAthletes.has(p.athlet_id)) {
        const athlete = filteredAthletes.find((a) => a.id === p.athlet_id);
        uniqueAthletes.set(p.athlet_id, {
          id: p.athlet_id,
          name: athlete ? athlete.name : "",
          wins: winsByAthlete[p.athlet_id]
            ? winsByAthlete[p.athlet_id].wins
            : 0,
        });
      }
    });

    return Array.from(uniqueAthletes.values()).filter(
      (athlete) => athlete.wins > 0
    );
  }, [games]);

  if (!games) {
    return <h1>Загрузка...</h1>;
  }

  return (
    <div className="App" style={{ textAlign: "center" }}>
      <h1>Таблица лидеров</h1>
      <input
        type="text"
        placeholder="Поиск атлета"
        onChange={(e) => setSearchAthlete(e.target.value)}
        className="search-input"
      />
      {loading ? (
        <h1>Загрузка...</h1>
      ) : filteredAthletes.length > 0 ? (
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>№</th>
              <th>Имя атлета</th>
              <th>Победы</th>
            </tr>
          </thead>
          <tbody>
            {leaderBoard
              .sort((a, b) => b.wins - a.wins)
              .map((athlete, index) => (
                <tr key={athlete.id}>
                  <td>{index + 1}</td>
                  <td>{athlete.name}</td>
                  <td>{athlete.wins}</td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <h2>Результаты не найдены</h2>
      )}
    </div>
  );
}

export default LeaderBoard;