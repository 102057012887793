import React from 'react'

function NotFound() {
  return (
    <div className='App' style={{ textAlign: "center", padding: "50px" }}>
      <h1>404 - Страница не найдена</h1>
      <p>К сожалению, такой страницы не существует.</p>
      <a href="/" style={{ color: "blue", textDecoration: "underline" }}>
        Вернуться на главную
      </a>
    </div>
  )
}

export default NotFound